.clients-table-header,
.clients-action-buttons {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.clients-action-buttons {
  margin-bottom: 1rem;
}

.select-filter {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.error-span {
  color: red;
}
