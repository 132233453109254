.dark-scan-bg {
  width: 300px;
  height: 225px;
  background-color: rgba(1, 2, 1, 0.5);
  position: absolute;
  z-index: 1;
  top: 74px;
}

.lines {
  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 150%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.profile-text-info {
  display: flex;
}

.profile-text-info p {
  font-weight: 500;
  font-size: 16px;
}

.client-details-section {
  margin-left: 3rem;
}

.client-details-section .ant-tag {
  font-size: 14px;
}
