@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
body {
  font-family: 'Lora', sans-serif;
}
body,
html {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.main-content-container {
  margin: 10px 16px;
}

.main-content {
  padding: 24px;
  min-height: 640px;
}

@media only screen and (max-width: 600px) {
  body {
    overflow: auto;
  }
}
