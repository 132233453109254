.capture-photo-modal {
  width: 650px !important;
}

.capture-button {
  width: 25%;
  margin-right: 0.5rem;
  margin-top: 1rem;
  align-self: flex-end;
}
