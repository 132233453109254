.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout-section {
  display: flex;
  justify-content: center;
}

.logout-section span {
  color: rgba(255, 255, 255, 0.65);
}
