.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-alert {
  width: 400px;
  margin-bottom: 1rem;
}
